@import '../../../../variables.css';

.categoryCard {
    min-height: 8.75rem;
    width: 8.75rem;
    padding-bottom: 1rem;
    text-decoration: none;
    transform: translateY(0);
    transition: box-shadow 0.664s var(--ark-ease-in-out-sine), transform 0.332s var(--ark-ease-in-out-sine);
    display: block;
    box-sizing: content-box;
}

.categoryCard.pressedState {
    transform: translateY(-5px);
}

.cardContainer {
    min-height: 8.75rem;
    width: 8.75rem;
    border-radius: 0.625rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 20px -5px var(--ark-gray-shadow-for-all-categories);
}

.cardContainer:hover {
    box-shadow: 0 14px 20px -5px var(--ark-gray-shadow-hover-for-all-categories);
}

.categoryCardBack {
    height: 6.25rem;
    position: relative;
    flex-shrink: 0;
    background-color: rgb(114, 122, 35); /* todo: refactor when will move categories to headless CMS */
}

.categoryCardCaption {
    padding: 0.5rem 0.75rem;
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-black-color);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    flex-grow: 1;
}

.categoryImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
    height: 5rem;
}

.all .categoryCardBack {
    background-color: var(--ark-purple-1);
}

.best .categoryCardBack,
.summer-favorites .cardContainer,
.popular .categoryCardBack {
    background-color: var(--ark-orange-1);
}

.new .categoryCardBack {
    background-color: var(--ark-green-1);
}

.card .categoryCardBack {
    background-color: var(--ark-turquoise-1);
}

.strategy .categoryCardBack {
    background-color: var(--ark-blue-2);
}

.word .categoryCardBack {
    background-color: var(--ark-blue-1);
}

.puzzles .categoryCardBack {
    background-color: var(--ark-purple-2);
}

.originals .categoryCardBack {
    background-color: var(--ark-purple-2);
}

.stan-newmans-crossword .categoryCardBack {
    background-color: var(--ark-blue-2);
}

.crosswords .categoryCardBack {
    background-color: var(--ark-blue-2);
}

.solitaire .categoryCardBack {
    background-color: var(--ark-turquoise-1);
}

.mahjong .categoryCardBack {
    background-color: var(--ark-green-1);
}

.addicting .categoryCardBack {
    background-color: var(--ark-red-1);
}

.math .categoryCardBack {
    background-color: var(--ark-blue-2);
}

.arcade .categoryCardBack {
    background-color: var(--ark-purple-2);
}

.sudoku .categoryCardBack {
    background-color: var(--ark-green-2);
}

.spelling .categoryCardBack {
    background-color: var(--ark-green-1);
}

.board .categoryCardBack {
    background-color: var(--ark-yellow-1);
}

.hidden-object .categoryCardBack {
    background-color: var(--ark-blue-1);
}

.racing .categoryCardBack {
    background-color: var(--ark-purple-2);
}

.poker .categoryCardBack {
    background-color: var(--ark-purple-2);
}

.sports .categoryCardBack {
    background-color: var(--ark-orange-1);
}

.brain .categoryCardBack {
    background-color: var(--ark-blue-1);
}

.indevelopment .categoryCardBack {
    background-color: var(--ark-blue-2);
}

.jigsaws .categoryCardBack {
    background-color: var(--ark-turquoise-1);
}

.match3 .categoryCardBack {
    background-color: var(--ark-pink-1);
}

.casino .categoryCardBack {
    background-color: var(--ark-green-2);
}

.memory .categoryCardBack {
    background-color: var(--ark-blue-1);
}

.game-shows .categoryCardBack {
    background-color: var(--ark-blue-2);
}

.daily-games .categoryCardBack,
.jeux-quotidiens .categoryCardBack {
    background-color: var(--ark-pink-1);
}

.daily-games .categoryCardBack,
.jeux-quotidiens .categoryCardBack {
    background-color: var(--ark-pink-1);
}

.retro .categoryCardBack {
    background-color: var(--ark-light-purple);
}

.multiplayer-games .categoryCardBack {
    background-color: var(--ark-light-blue);
}

.car-games-online .categoryCardBack {
    background-color: var(--ark-bright-yellow);
}

.browser-games .categoryCardBack {
    background-color: var(--ark-bright-green);
}

.flash-games .categoryCardBack {
    background-color: var(--ark-deep-red);
}

.trivia-games .categoryCardBack {
    background-color: var(--ark-deep-blue);
}

.3d-games .categoryCardBack {
    background-color: var(--ark-green-2);
}

.bubble-games .categoryCardBack {
    background-color: var(--ark-turquoise-1);
}

.block-games .categoryCardBack {
    background-color: var(--ark-blue-2);
}

.pool-games .categoryCardBack {
    background-color: var(--ark-orange-1);
}

.subscriber-only-games .categoryCardBack {
    background-color: var(--ark-purple-3);
}